import React, { createContext, useState, useEffect } from 'react';
import { loadWords } from '../scripts/LoadWords';

const WordsContext = createContext();

export const WordsProvider = ({ children }) => {
  const [wordsList, setWordsList] = useState([]);

  useEffect(() => {
    const fetchWords = async () => {
      const words = await loadWords();
      setWordsList(words);
    };

    fetchWords();
  }, []);

  return (
    <WordsContext.Provider value={wordsList}>
      {children}
    </WordsContext.Provider>
  );
};

export const useWords = () => React.useContext(WordsContext); 