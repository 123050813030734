import { useEffect } from 'react';

export const loadWords = async () => {
  console.log('Loading words list...');

  try {
    // Fetch the words.txt file using a path relative to the public directory
    const response = await fetch('/scrabble/words.txt'); // Adjust the path as needed
    const text = await response.text();

    // Split the text by new lines to create an array of words
    const wordsList = text.split('\n').map(word => word.trim()).filter(word => word);

    console.log(`Words list loaded. Total words: ${wordsList.length}`);
    return wordsList;
  } catch (error) {
    console.error('Error loading words list:', error);
    return [];
  } finally {
    console.log('Finished loading words list.');
  }
};

const LoadWords = () => {
  useEffect(() => {
    loadWords();
  }, []);

  return null; // This component doesn't render anything
};

export default LoadWords;
